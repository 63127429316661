import styled from '@emotion/styled';
import { breakpoints } from '~/theme';

export const StyledImageContainer = styled.div(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    gap: theme.spaces[2],
    [breakpoints.sm]: {
        gap: theme.spaces[3],
    },
    img: {
        maxWidth: 35,
        [breakpoints.sm]: {
            maxWidth: 45,
        },
    },
}));

export const StyledMeta = styled.div(({ theme }) => ({
    display: 'grid',
    gap: theme.spaces[6],
    gridTemplateColumns: 'auto auto',
    justifyContent: 'space-between',
    borderTop: `1px solid ${theme.colors.grey40}`,
    marginTop: theme.spaces[9],
    paddingTop: theme.spaces[4],
    paddingBottom: theme.spaces[4],
}));
