import React from 'react';
import { useMobileMenu } from '../../../N35MobileMegaMenu/hooks/useMobileMenu';
import { useScrollLock } from 'utils';
import { Button } from 'ui';
import clsx from 'clsx';
import styles from './MobileMenuButton.module.css';
import { useTranslations } from '~/services/cms';

export const MobileMenuButton = () => {
    const { isOpen, toggle } = useMobileMenu();
    const translate = useTranslations()
    const { lock, unlock } = useScrollLock();


    const toggleMenu = () => {
        if (isOpen) {
            unlock();
        } else {
            lock();
        }
        toggle();
    }

    return (
        <Button
            className={clsx(styles.mobileMenuButton)}
            iconName={isOpen ? 'close' : 'menu'}
            variant='link'
            onClick={toggleMenu}
            aria-label={translate('navigation.mobile.mainMenu.name')}
        />
    );
};
