import { LinkDeprecated, StyledAccordionItem } from 'ui';
import { breakpoints } from '$theme';
import styled from '@emotion/styled';

export const StyledMobileMenu = styled.div({
    display: 'grid',
    [breakpoints.sm]: {
        display: 'none',
    },
});

export const StyledDesktopMenu = styled.div(({ theme }) => ({
    display: 'none',
    gap: theme.spaces['4'],
    [breakpoints.sm]: {
        display: 'grid',
    },
}));

export const StyledMenuContainer = styled.div({
    display: 'grid',
    alignItems: 'start',
    gridTemplateColumns: '1fr',
    gridTemplateRow: 'min-content',
    gridAutoRows: 'max-content',
    [breakpoints.sm]: {
        gridTemplateColumns: 'repeat(3, 1fr)',
        rowGap: '2.5em',
    },
    [breakpoints.md]: {
        gridTemplateColumns: 'repeat(3, 1fr)',
    },
});

export const StyledMenuGroup = styled.div({
    display: 'block',
    gridAutoFlow: 'row',
    gridTemplateColumns: 'max-content',
    [breakpoints.sm]: {
        paddingRight: '2.5em',
    },
    [breakpoints.md]: {
        display: 'grid',
    },
    [`& + & ${StyledAccordionItem}`]: {
        borderTop: 0,
    },
});

export const StyledMenuItem = styled(LinkDeprecated)(({ theme }) => ({
    fontSize: theme.fontSizes.xs,
    textDecoration: 'none',
    [breakpoints.xs]: {
        textDecoration: 'underline',
    },
}));

export const StyledMenuItems = styled.ul(({ theme }) => ({
    display: 'grid',
    gap: theme.spaces['4'],
}));
