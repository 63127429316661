import NextLink from 'next/link';
import React from 'react';
import { CmsModel } from 'api-types';
import { weakKey } from '~/shared/utils/jsx';
import clsx from 'clsx';
import styles from './MobileMetaMenu.module.css';
import { Link } from 'ui';

type Props = {
    menu: CmsModel.Link[];
};

export const MobileMetaMenu = ({ menu }: Props) => {
    return (
        <ul className={clsx(styles.list)}>
            {menu.map(
                (link) =>
                    link.url && (
                        <li key={weakKey(link)}>
                            <NextLink href={link.url} passHref prefetch={false}>
                                <Link
                                    className={clsx(styles.link)}
                                    children={link.title}
                                    title={link.title}
                                    target={link.target}
                                />
                            </NextLink>
                        </li>
                    )
            )}
        </ul>
    );
};
