import { MaxWidth, Gutter } from 'ui';
import { useFrame } from '~/services/cms';
import React from 'react';
import { withErrorBoundary } from '~/shared/utils/errorBoundary';
import { MenuArea } from './components/MenuArea';
import { MetaArea } from './components/MetaArea';
import { NewsletterArea } from './components/NewsletterArea';
import { RichTextArea } from './components/RichTextArea';
import { MarketSelector } from './components/MarketSelector';

import {
    StyledFooter,
    StyledGrid,
    StyledSocialBanner,
    StyledRights,
    StyledFooterBanner,
} from './styled';
import { SocialArea } from './components/SocialArea';

export const N60Footer = () => {
    const { data: frame } = useFrame();

    const {
        contactText,
        logoImage,
        menu,
        newsletterText,
        newsletterLink,
        paymentIcons,
        additionalIcons,
        socialLinks,
        copyrights,
    } = frame?.footer || {};

    const showMetaArea = paymentIcons?.length || additionalIcons?.length;
    const showGrid = menu || contactText || logoImage || newsletterText || newsletterLink;
    const showSocialIcons = socialLinks?.length;

    return (
        <StyledFooter>
            <MaxWidth>
                <Gutter>
                    {showGrid && (
                        <StyledGrid>
                            {menu && <MenuArea menu={menu} />}
                            <MarketSelector />
                            {contactText || logoImage ? (
                                <RichTextArea text={contactText} logoImage={logoImage} />
                            ) : null}

                            {newsletterLink ? (
                                <NewsletterArea text={newsletterText} link={newsletterLink} />
                            ) : null}
                        </StyledGrid>
                    )}
                    {showMetaArea ? (
                        <MetaArea paymentIcons={paymentIcons} additionalIcons={additionalIcons} />
                    ) : null}
                </Gutter>
            </MaxWidth>
            {showSocialIcons ? (
                <StyledSocialBanner>
                    <MaxWidth>
                        <Gutter>
                            <StyledFooterBanner>
                                {copyrights ? <StyledRights>{copyrights}</StyledRights> : null}
                                <SocialArea socialIcons={socialLinks} />
                            </StyledFooterBanner>
                        </Gutter>
                    </MaxWidth>
                </StyledSocialBanner>
            ) : null}
        </StyledFooter>
    );
};

export default withErrorBoundary(N60Footer);
