import styled from '@emotion/styled';
import { breakpoints } from '$theme';

export const StyledFooter = styled.footer(({ theme }) => ({
    backgroundColor: theme.colors.grey20,
    paddingTop: theme.spaces[8],
    overflow: 'visible',
    '&:empty': {
        display: 'none',
    },
    paddingBottom: '220px',
    [breakpoints.sm]: {
        paddingBottom: 0,
        overflow: 'hidden',
    }
}));

export const StyledGrid = styled.div(({ theme }) => ({
    display: 'grid',
    rowGap: theme.spaces[6],
    gridAutoRows: 'max-content',
    paddingBottom: theme.spaces[2],
    gridTemplateAreas: `"menu"
                        "richtext"
                        "newsletter"
                        "market"`,
    '&:empty': {
        display: 'none',
    },
    [breakpoints.sm]: {
        justifyContent: 'space-between',
        gridTemplateColumns: '1fr 250px',
        gridTemplateAreas: `"menu market richtext"
                            "menu market newsletter"`,
    },
    [breakpoints.md]: {
        gridTemplateColumns: '60% 20% 20%',
    },
}));

export const StyledFooterBanner = styled.div({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [breakpoints.xs]: {
        flexDirection: 'column',
        justifyContent: 'center',
    },
});

export const StyledSocialBanner = styled.div(({ theme }) => ({
    height: theme.spaces['6'],
    width: '100%',
    backgroundColor: theme.colors.black,
    [breakpoints.xs]: {
        height: theme.spaces['10'],
    },
}));

export const StyledRights = styled.div(({ theme }) => ({
    fontSize: theme.fontSizes.sm,
    color: theme.colors.white,
    [breakpoints.xs]: {
        height: theme.spaces['6'],
        display: 'flex',
        alignItems: 'center',
    },
}));
