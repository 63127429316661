import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { useFrame } from '~/services/cms';
import { Drawer } from './components/Drawer';
import { MobileMegaMenu } from './components/MobileMegaMenu';
import { useMobileMenu } from './hooks/useMobileMenu';
import { MobileMetaMenu } from './components/MobileMetaMenu';

export type N35MobileMegaMenuProps = {
    /**
     * Set the id of the active node. Overwrides the activeNodeByUrl.
     * NOTE: Only checked on init
     */
    activeNodeId?: string;

    /**
     * Determin the active node by the location url.
     * NOTE: Only checked on init
     */
    activeNodeByUrl?: boolean;
};

/**
 * Mega menu for mobile. This menu renders each step as an menu page.
 */
export const N35MobileMegaMenu = ({
    activeNodeId,
    activeNodeByUrl = true,
}: N35MobileMegaMenuProps) => {
    const { isOpen, close } = useMobileMenu();
    const { data } = useFrame();
    const { mainMenu = [], metaMenu = [] } = data?.navigation || {};
    const { events } = useRouter();

    useEffect(() => {
        events.on('routeChangeStart', close);
        return () => events.off('routeChangeStart', close);
    }, [events, close]);

    return (
        <Drawer open={isOpen} onClose={() => close()}>
            <MobileMegaMenu
                menu={mainMenu}
                activeNodeByUrl={activeNodeByUrl}
                activeNodeId={activeNodeId}
            />
            {!!metaMenu?.length && <MobileMetaMenu menu={metaMenu} />}
        </Drawer>
    );
};
