import React, { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { useFrame } from '~/services/cms';
import { format } from 'url';

import { StyledSearchButton } from './styled';
import { InputField, SvgIcon } from 'ui';
import { useMedia } from 'react-use';
import { breakpoints, sizes } from '~/constants';
import { motion } from 'framer-motion';
import { useTranslations } from '~/services/cms';

import clsx from 'clsx';
import styles from './N25SearchBar.module.css';
import { getRouterQueryParamsWithoutPagination } from '~/components/features/ProductsList/Facets/use-facets';


export interface QueryParamsType {
    [x: string]: string;
}[]

export const N25SearchBar = ({ shouldAutoHide }: { shouldAutoHide: boolean }) => {
    const router = useRouter();
    const translate = useTranslations()
    const { data: frameData } = useFrame();
    const isMobile = !useMedia(`(min-width: ${breakpoints.md}px)`, false);
    const [inputValue, setInputValue] = useState<string>('');


    let searchUrl = frameData?.staticLinks?.searchPage?.url;

    useEffect(() => {
        if (frameData?.staticLinks?.searchPage?.url) {
            searchUrl = frameData?.staticLinks?.searchPage?.url;
        }
    }, [frameData?.staticLinks]);

    const searchInputRef = useRef<HTMLInputElement>(null);


    useEffect(() => {
        if (router.isReady) {
            const routerQuery = router?.query?.query as string;
            if (routerQuery && routerQuery.length > 0) {
                setInputValue(routerQuery);
            }
        }
    }, [router.isReady]);

    useEffect(() => {
        if (!router.isReady) return;
        onInputChange(inputValue)
    }, [inputValue]);


    const onInputChange = (inputQuery: string) => {
        const routerParams = getRouterQueryParamsWithoutPagination(router.query);
        const queryParams: QueryParamsType = {
            ...routerParams,
            'query': decodeURIComponent(inputQuery)
        };

        if (!inputQuery?.length && queryParams) {
            // Complete remove query param if no values left or no facetValue
            delete queryParams?.query;
        }

        const url = format({
            pathname: searchUrl,
            query: queryParams,
        })
        router.replace(
            url,
            url,
            { shallow: true }
        )
    };


    const clickedSearchButton = () => {
        searchInputRef?.current?.focus();
        if (inputValue.length > 0) {
            setInputValue('');
        }
    };

    const handleOnFocus = () => {
        if (inputValue.length > 0) {
            // reset input value to move cursor to the end of the input
            setInputValue(inputValue);
            onInputChange(inputValue)
        } else {
            router.push(
                `${searchUrl}`,
                `${searchUrl}`
            )
        }
    }

    const styleSearchHeight = { "--search-height": `${sizes.searchHeight}px` } as React.CSSProperties;

    const variants = {
        visible: { y: isMobile ? `calc(100% + ${sizes.searchHeight}px + 2px)` : '0%' }, //because of top border
        hidden: { y: '100%' },
    };

    return (
        <motion.div
            className={clsx(styles.searchBox)}
            style={styleSearchHeight}
            animate={shouldAutoHide ? 'hidden' : 'visible'}
            variants={variants}
            initial={'visible'}
            transition={{ duration: isMobile ? 0.5 : 0 }}
        >
            <InputField
                id="search-input"
                ref={searchInputRef}
                value={inputValue}
                onChange={(event) => setInputValue(event.currentTarget.value)}
                onFocus={handleOnFocus}
                placeholder={translate('header.search')}
            />
            <StyledSearchButton
                style={styleSearchHeight}
                onClick={clickedSearchButton}
                shape="icon"
                variant="tertiary"
                children={<SvgIcon name={inputValue?.length > 0 ? 'close' : 'search'} />}
                description={'Search'}
            />
        </motion.div>
    );
};
