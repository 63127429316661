import { Variants } from 'framer-motion';

const nonAnimatedVariants: Variants = {
    active: {
        opacity: 1,
    },
    inactive: {
        opacity: 0,
        transitionEnd: {
            display: 'none',
        },
    },
};

const animatedVariants: Variants = {
    active: {
        opacity: 1,
        transition: {
            duration: 0.25,
            delay: 0.25,
            ease: 'easeOut',
        },
    },
    inactive: {
        opacity: 0,
        transition: {
            duration: 0.25,
            ease: 'easeOut',
        },
        transitionEnd: {
            display: 'none',
        },
    },
};

export const variants = (prefersReducedMotion: boolean | null): Variants =>
    prefersReducedMotion ? nonAnimatedVariants : animatedVariants;

export const animatedOverlay: Variants = {
    active: {
        opacity: 0.2,
        transition: {
            duration: 0.25,
            delay: 0.25,
            ease: 'easeOut',
        },
    },
    inactive: {
        opacity: 0,
        transition: {
            duration: 0.25,
            ease: 'easeOut',
        },
        transitionEnd: {
            display: 'none',
        },
    },
};
