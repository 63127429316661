import React, { memo, useState } from 'react';
import { CmsModel } from 'api-types';
import { animationOptions } from './animations';
import { Usp as UspItem } from 'ui';
import { useInterval } from 'react-use';
import { motion, Variants } from 'framer-motion';
import { weakKey } from '~/shared/utils/jsx';
import styles from './Usp.module.css';

export type Props = {
    uspItems?: CmsModel.UspItem[];
    animationDelay?: number;
    animationType?: Variants;
};

/**
 * Takes a list of USP's and
 * loops through them. Animation
 * properties can be controlled.
 */

export const Usp = memo(
    ({ uspItems = [], animationDelay = 5000, animationType = animationOptions.fade }: Props) => {
        const [shownItem, setShownItem] = useState(0);
        const [paused, setPaused] = useState(false);
        const [isTabFocus, setIsTabFocus] = useState(false);

        useInterval(
            () => {
                setShownItem((prev) => ++prev % uspItems.length);
            },
            paused ? null : animationDelay
        );

        return (
            <div className={styles.uspContainer}
                onMouseOver={() => setPaused(true)}
                onMouseOut={() => setPaused(false)}
                onBlur={(event) => {
                    if (!event.currentTarget.contains(event.relatedTarget as Node)) {
                        setIsTabFocus(false);
                    }
                }}
                onKeyDown={(event) => {
                    if (event.code === 'Tab') {
                        setIsTabFocus(true);
                    }
                }}
            >
                {isTabFocus ? (
                    <div className={styles.wrapper}>
                        <ul className={styles.accesibilityUspDisplay}>
                            {uspItems.map((usp) => (
                                <li className={styles.liWrapper} key={weakKey(usp)}>
                                    <UspItem uspItem={usp} />
                                </li>
                            ))}
                        </ul>
                    </div>
                ) : (
                    uspItems.map((usp, index) => (
                        <motion.div
                            key={weakKey(usp)}
                            variants={animationType}
                            animate={shownItem === index ? 'animate' : 'exit'}
                            style={{ pointerEvents: shownItem !== index ? 'none' : 'auto' }}
                            initial="initial"
                        >
                            <UspItem uspItem={usp} shade="light" />
                        </motion.div>
                    ))
                )}
            </div>
        );
    }
);
