import React, { memo, ReactNode, useMemo, useRef, useState } from 'react';

import clsx from 'clsx';
import styles from './Drawer.module.css';

type DrawerProps = {
    open: boolean;
    children: ReactNode;
    onClose?: () => void;
};

export const Drawer = memo(({ children, open, onClose }: DrawerProps) => {
    const drawerContainerRef = useRef<HTMLDivElement>(null);
    const [isVisible, setIsVisible] = useState<boolean>(open);

    const offset = useMemo(() => {
        if (drawerContainerRef.current) {
            return drawerContainerRef.current.getBoundingClientRect().top - 1;
        }
        return 0;
    }, [open]);

    const onKeyDownHandler = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Escape') {
            onClose?.();
        }
    };

    return (
        <div className={clsx(styles.drawerContainer, {
            [styles.open]: open
        })} onKeyDown={onKeyDownHandler} ref={drawerContainerRef}>
            <div className={clsx(styles.drawer, {
                [styles.open]: open
            })}
                onAnimationEnd={() => setIsVisible(open)}
                style={{ display: open || isVisible ? '' : 'none', top: offset ? `${offset}px` : 0 }}
            >
                <div className={clsx(styles.content)} children={children} />
            </div>
            <div className={clsx(styles.backdrop, {
                [styles.open]: open
            })} onClick={onClose} />
        </div>
    );
});
