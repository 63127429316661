import React, { useEffect, useRef } from 'react';
import { CmsModel } from 'api-types';
import { useTranslations } from '~/services/cms';
import { NodeList } from '../NodeList';
import { Text, Link, Button } from 'ui';
import NextLink from 'next/link';

import styles from './MenuPage.module.css';

type MenuPageProps = {
    nodes: CmsModel.NavigationNode[];
    parentNode?: CmsModel.NavigationNode;
    headlineText?: string;
    headlineLinkUrl?: string;
    backText?: string;
    onSelectNode?: (state?: CmsModel.NavigationNode) => void;
    onGoBack?: () => void;
};

export const MenuPage = ({
    nodes,
    headlineText,
    headlineLinkUrl,
    backText,
    onSelectNode,
    onGoBack,
}: MenuPageProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const translate = useTranslations();

    /**
     * Focus first link on open
     */
    useEffect(() => {
        const element = ref.current;

        if (element) {
            const firstFocusableElement = element.querySelector<HTMLAnchorElement>('a, button');
            firstFocusableElement?.focus();
        }
    }, [nodes]);

    /**
     * Support keyboard navigation up and down within the navigation
     */
    const onKeyDownHandler = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (!ref.current) {
            return;
        }

        const activeElement = document.activeElement as HTMLAnchorElement;
        const focusableElements =
            ref.current?.querySelectorAll<HTMLAnchorElement>('a, button') || [];
        const currentFocusIndex = Array.from(focusableElements).indexOf(activeElement);

        if (event.key === 'ArrowDown') {
            const focusIndex = Math.min(currentFocusIndex + 1, focusableElements.length - 1);
            focusableElements[focusIndex]?.focus();
            event.preventDefault();
        } else if (event.key === 'ArrowUp') {
            const focusIndex = Math.max(currentFocusIndex - 1, 0);
            focusableElements[focusIndex]?.focus();
            event.preventDefault();
        }
    };

    return (
        <div className={styles.menuPage} ref={ref} onKeyDown={onKeyDownHandler}>
            {headlineText && (
                <div className={styles.header}>
                    <div className={styles.backContainer}>
                        {backText && (
                            <Button variant="link" iconName='chevron-left' onClick={onGoBack}>
                                <span>
                                    {translate('navigation.mobile.mainMenu.goBackTo')}{' '}
                                    {backText.toLocaleLowerCase()}
                                </span>
                            </Button>
                        )}
                    </div>
                    <Text variant='bodyLarge' as="b" children={headlineText} />
                    {headlineLinkUrl && (
                        <NextLink href={headlineLinkUrl} passHref>
                            <Link
                                href={headlineLinkUrl}
                                children={translate('navigation.mobile.mainMenu.viewAll')}
                            />
                        </NextLink>
                    )}
                </div>
            )}
            {nodes?.length ? <NodeList nodes={nodes} onSelectNode={onSelectNode} /> : null}
        </div>
    );
};
