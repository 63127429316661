import { Button, Text } from 'ui';
import React from 'react';
import styles from './LoginButton.module.css';
import clsx from 'clsx';
import { useFrame, useTranslations } from '~/services/cms';
import { useAuthentication } from '~/shared/hooks';

export const LoginButton = () => {
    const { data: frameData } = useFrame();
    const translate = useTranslations();
    const { isAuthenticated, isAuthenticating, session } = useAuthentication();

    const staticLinks = frameData?.staticLinks;

    if (!staticLinks?.loginPage || isAuthenticating) {
        return null;
    }

    return (
        <Button
            className={clsx(styles.buttonPadding, styles.link)}
            href={!isAuthenticated ? staticLinks?.loginPage.url : staticLinks?.profilePage?.url}
            variant='link'
            iconName={!isAuthenticated ? 'login' : 'user'}
            aria-label={translate('auth.login')}
            children={
                <Text className={clsx(styles.link, styles.loginText)} variant='bodySmall'>
                    {!isAuthenticated ? translate('auth.login') : session?.user?.firstName}
                </Text>
            }
        />
    )
};
