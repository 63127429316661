import React from 'react';
import { CmsModel } from 'api-types';
import { GridItem } from '../GridItem';
import { Icons } from './components/Icons';
import { StyledMeta } from './styled';

type MetaAreaProps = {
    paymentIcons?: CmsModel.FooterIcon[];
    additionalIcons?: CmsModel.FooterIcon[];
};

export const MetaArea = ({ paymentIcons, additionalIcons }: MetaAreaProps) => {
    return (
        <GridItem area="meta">
            <StyledMeta>
                {paymentIcons?.length ? <Icons icons={paymentIcons} /> : null}
                {additionalIcons?.length ? <Icons icons={additionalIcons} /> : null}
            </StyledMeta>
        </GridItem>
    );
};
