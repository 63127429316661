export const breakpoints = {
    /**
     * Mobile devices. Use orientation media queries for landscape styling
     */
    xs: 767,
    /**
     * Small tablets
     */
    sm: 768,
    /**
     * Large tablets, small laptops
     */
    md: 1024,
    /**
     * Desktops, laptops
     */
    lg: 1367,
    /**
     * Wide screens
     */
    xl: 1730,
};

export const sizes = {
    // Limit content
    contentMaxWidth: 1690,
    headerHeightDesktop: 70,

    // Limit page width. Background colors etc.
    pageMaxWidth: 1690,

    // Header checkout
    headerHeightCheckout: 93,
    checkoutMinHeaderHeight: 130,

    content: 'min(88vw, 1690px)',

    // Default Width for side Drawer
    drawerWidth: 480,
    mobileDrawerWidth: 300,

    // Search bar height
    searchHeight: 46,
};

export const aspectRatios = {
    imageProduct: '2475 / 3262',
    imageModel: '2 / 3',
};
