import React, { ComponentType, FC } from 'react';
import { ErrorBoundary } from './ErrorBoundary';

/**
 * HOC to wrap the export, provide a optional fallback component
 * @example
 * export default withErrorBoundary(MyComponent, <ErrorBoundaryFallback description="This content is currently not available"/>)
 */
export function withErrorBoundary<Props = Record<string, unknown>>(
    WrappedComponent: ComponentType,
    FallBack?: FC<unknown> | JSX.Element
): FC<Props> {
    return () => (
        <ErrorBoundary fallback={FallBack}>
            <WrappedComponent />
        </ErrorBoundary>
    );
}
