import styled from '@emotion/styled';
import { breakpoints } from '~/theme';

export const StyledImageContainer = styled.div(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    gap: theme.spaces[2],
    [breakpoints.sm]: {
        gap: theme.spaces[3],
    },
    img: {
        maxWidth: 35,
        [breakpoints.sm]: {
            maxWidth: 45,
        },
    },
}));

export const StyledIconsContainer = styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    height: theme.spaces['6'],
    flex: 1,
    justifyContent: 'flex-end',
}));
