import React from 'react';
import { CmsModel } from 'api-types';
import { weakKey } from '~/shared/utils/jsx';
import { NodeItem } from '../NodeItem';
import clsx from 'clsx';
import styles from './NodeList.module.css';

type NodeListProps = {
    nodes: CmsModel.NavigationNode[];
    onSelectNode?: (node: CmsModel.NavigationNode) => void;
};
export const NodeList = ({ nodes, onSelectNode }: NodeListProps) => (
    <ul style={{ paddingLeft: 0 }}>
        {nodes?.map((node) => (
            <li className={clsx(styles.listItem)} key={weakKey(node)}>
                <NodeItem node={node} onSelectNode={onSelectNode} />
            </li>
        ))}
    </ul>
);
