import { RawHtml } from 'ui';
import { GridItem, GridItemProps } from '../GridItem';
import { Logo } from '~/components/features';
import { CmsModel } from 'api-types';

type RichTextAreaProps = GridItemProps & {
    text?: string;
    logoImage?: CmsModel.ImageMedia;
};

export const RichTextArea = ({ text, logoImage }: RichTextAreaProps) => {
    return (
        <GridItem area="richtext">
            {logoImage && <Logo description="Logo - Frontpage" />}
            {text && <RawHtml html={text} />}
        </GridItem>
    );
};
