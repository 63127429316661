import { Button } from 'ui';
import React from 'react';
import clsx from 'clsx';
import styles from './FavoriteButton.module.css';
import { useRouter } from 'next/router';
import { useAuthentication } from '~/shared/hooks';
import { useFrame } from '~/services/cms';

export const FavoriteButton = () => {
    const { isAuthenticated } = useAuthentication();

    const { data: frameData } = useFrame();
    const staticLinks = frameData?.staticLinks;

    const { push } = useRouter()
    if (!isAuthenticated) return null

    if (!staticLinks?.favoritesPage?.url) return null;

    return (
        <Button
            onClick={() => push(staticLinks?.favoritesPage?.url || '')}
            className={clsx(styles.buttonPadding)}
            variant='link'
            iconName='star'
        />
    );
}
