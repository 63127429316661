import { useFrame } from '~/services/cms';
import React from 'react';
import { N35MobileMegaMenu } from '$features/Navigation';
import { N30MegaMenu } from '$features/Navigation/components/N30MegaMenu';
import { Logo } from '~/components/features';
import { withErrorBoundary } from '~/shared/utils/errorBoundary';
import { FavoriteButton } from './components/FavoriteButton';
import { MobileMenuButton } from './components/MobileMenuButton';
import { LoginButton } from './components/LoginButton';
import { N25SearchBar } from '../N25SearchBar';
import { useAuthentication, useScrollDirection } from '~/shared/hooks';
import { useMedia } from 'react-use';
import { motion } from 'framer-motion';
import { sizes, breakpoints } from '~/constants';

import clsx from 'clsx';
import styles from './N20MainMenu.module.css';

import { BasketButton } from './components/BasketButton';
import { isJPG } from 'utils';

const THRESHOLD = 20;

const N20MainMenu = () => {
    const { data } = useFrame();
    const { session } = useAuthentication();
    const isJPGroup = isJPG();

    const { mainMenu = [] } = data?.navigation || {};

    const scrollDirection = useScrollDirection({ threshold: THRESHOLD });
    const isMobile = !useMedia(`(min-width: ${breakpoints.md}px)`, false);
    const shouldHideSearch = scrollDirection === 'down' && isMobile;

    const variants = {
        visible: { y: 0 },
        hidden: { y: '-100%' },
    };

    const styleSearchHeight = { "--search-height": `${sizes.searchHeight}px` } as React.CSSProperties;
    const styledHeaderHeightDesktop = { "--header-height-desktop": `${sizes.headerHeightDesktop}px` } as React.CSSProperties;
    const styledContentMaxWidth = {
        "--content-max-width": `${sizes.contentMaxWidth}px`,
        "--header-height-desktop": `${sizes.headerHeightDesktop}px`
    } as React.CSSProperties;

    return (
        <header className={clsx(styles.mainNavigation)} style={styledHeaderHeightDesktop}>
            <div className={clsx(styles.content, {
                [styles.loggedIn]: session?.accessToken && session?.accessToken.length > 0 || !isJPGroup
            })} style={styledContentMaxWidth}>
                <div className={clsx(styles.logoContainer)}>
                    <Logo description="Logo - Frontpage" />
                </div>
                <N25SearchBar shouldAutoHide={shouldHideSearch} />
                <div className={clsx(styles.line)} style={styleSearchHeight} />
                <div className={clsx(styles.desktopNav)} style={styledHeaderHeightDesktop}>
                    <N30MegaMenu navigation={mainMenu} />
                </div>
                <nav className={clsx(styles.iconNav)}>
                    {isMobile && <LoginButton />}
                    <FavoriteButton />
                    <BasketButton />
                    <MobileMenuButton />
                </nav>
            </div>
            <motion.div
                className={clsx(styles.searchBoxPlaceholder)}
                style={styleSearchHeight}
                animate={shouldHideSearch ? 'hidden' : 'visible'}
                variants={variants}
                transition={{ duration: isMobile ? 0.5 : 0 }}
            />
            <N35MobileMegaMenu activeNodeByUrl={true} />
        </header >
    );
};

export default withErrorBoundary(N20MainMenu);
