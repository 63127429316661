import React from 'react';
import { CmsModel } from 'api-types';
import { SocialIcons } from './components/SocialIcons';
import { StyledIconsContainer } from './styled';

type SocialAreaProps = {
    socialIcons?: CmsModel.SocialIcon[];
};

export const SocialArea = ({ socialIcons }: SocialAreaProps) => {
    return (
        <StyledIconsContainer>
            {socialIcons?.length ? <SocialIcons icons={socialIcons} /> : null}
        </StyledIconsContainer>
    );
};
