import React from 'react';
import { CmsModel } from 'api-types';
import { Image } from 'ui';
import { StyledImageContainer } from '../styled';

export const Icons = ({ icons }: { icons: CmsModel.FooterIcon[] }) => {
    return (
        <StyledImageContainer>
            {icons.map(({ image, title }, index) => (
                <Image
                    src={image.src}
                    key={image.src + index}
                    title={title}
                    loading="lazy"
                    width={35}
                    height={20}
                    layout="intrinsic"
                    sizes="45px"
                />
            ))}
        </StyledImageContainer>
    );
};
