import { ConsentType } from 'src/components/features/Cookiebot/Cookiebot';
import { useNotification } from '~/shared/hooks/useNotification';
/**
 * Exposes methods that live on the window,can be set in globalization-queries.ts that CookieBot has added
 */
export const useCookieBot = () => {
    const { push } = useNotification();
    const showMissing = () =>
        push({
            duration: 5000,
            text: '#CookieBot does not exist on current environment',
        });
    const renewConsent = () => {
        if (window.CookieConsent?.renew) {
            window.CookieConsent.renew();
        } else {
            showMissing();
        }
    };
    const getConsentFor = (type: ConsentType) => window.Cookiebot?.consent[type];
    // TODO may need to update later on to renew/update consent

    return {
        renewConsent,
        getConsentFor,
    };
};
