import { Accordion } from 'ui';
import React from 'react';
import { CmsModel } from 'api-types';
import { weakKey } from '~/shared/utils/jsx';
import { GridItem } from '../GridItem';
import { MenuItem } from './components/MenuItem';
import { StyledMenuContainer } from './styled';

type MenuAreaProps = {
    menu?: CmsModel.NavigationNode[];
};

export type MenuItemProps = {
    menuItem: CmsModel.NavigationNode;
};

export const MenuArea = ({ menu }: MenuAreaProps) => (
    <GridItem area="menu">
        <Accordion>
            <StyledMenuContainer>
                {menu?.map((menuItem: MenuItemProps['menuItem']) => (
                    <MenuItem key={weakKey(menuItem)} menuItem={menuItem} />
                ))}
            </StyledMenuContainer>
        </Accordion>
    </GridItem>
);
