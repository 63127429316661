import styled from '@emotion/styled';
import { ButtonDeprecated } from 'ui';
import { breakpoints } from '~/theme';

export const StyledSearchButton = styled(ButtonDeprecated)(({ theme }) => ({
    backgroundColor: theme.colors.red,
    color: theme.colors.white,
    width: theme.sizes.searchHeight,
    height: theme.sizes.searchHeight,

    position: 'absolute',
    right: 0,
    top: 0,

    ['&:hover']: {
        backgroundColor: theme.colors.red,

        ['svg path']: {
            stroke: theme.colors.black,
        },
    },

    [breakpoints.md]: {
        width: `calc(${theme.sizes.searchHeight}px + 1px)`,
        height: `calc(${theme.sizes.searchHeight}px + 1px)`,
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px',
    },

    svg: {
        width: '24px',
        height: '24px',
    },
}));
