import React, { memo } from 'react';
import { motion } from 'framer-motion';
import clsx from 'clsx';
import styles from './Backdrop.module.css';

type Props = {
    activeNodeHeight?: number;
};

/**
 * Mimics an elements height based on it's `imitator` prop
 * handles resizing
 */
export const Backdrop = memo(({ activeNodeHeight }: Props) => {
    return (
        <motion.div className={clsx(styles.backdrop)}
            animate={{ height: activeNodeHeight }}
            transition={{ ease: [0.25, 0, 0, 1], duration: 0.55 }}
        />
    );
});
