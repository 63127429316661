import NextLink from 'next/link';
import React from 'react';
import { ButtonDeprecated, RawHtml } from 'ui';
import { GridItem, GridItemProps } from '../GridItem';
import { CmsModel } from 'api-types';

type NewsletterAreaProps = GridItemProps & {
    link: CmsModel.Link;
    text?: string;
};

export const NewsletterArea = ({ text, link }: NewsletterAreaProps) => {
    return (
        <GridItem area="newsletter">
            <div>
                {text && <RawHtml html={text} />}
                {link?.url && (
                    <NextLink href={link.url} passHref>
                        <ButtonDeprecated
                            variant="secondary"
                            as="a"
                            target={link.target}
                            title={link.title}
                            children={link.title}
                        />
                    </NextLink>
                )}
            </div>
        </GridItem>
    );
};
