import { FC, ReactNode } from 'react';
import N10Meta from '$features/Navigation/components/N10Meta';
import N20MainMenu from '$features/Navigation/components/N20MainMenu';
//import N50Breadcrumb from '$features/Navigation/components/N50Breadcrumb';
import N60Footer from '$features/Navigation/components/N60Footer';
import { MetaFields } from '~/components/features';
import styles from '../layouts.module.css';
import { CookieConsent } from '~/components/features/Cookiebot';

interface IProps {
    children: ReactNode;
}

export const Standard: FC<IProps> = ({ children }) => (
    <>
        <MetaFields />
        <N10Meta />
        <N20MainMenu />
        <main className={styles.main}>
            {/*<N50Breadcrumb />*/}
            {children}
        </main>
        <CookieConsent />
        <N60Footer />
    </>
)


