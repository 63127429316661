import React, { useEffect, useState } from 'react';
import { useError } from '../hooks/useError';
import { Notification } from '~/components/features';
import { useTranslations } from '~/services/cms';
import { StyledContent } from './styled';

type Props = {
    description?: string;
};

export const ErrorBoundaryFallback = ({ description }: Props) => {
    const { error } = useError();
    const translate = useTranslations();
    const [dismiss, setDismiss] = useState(false);

    useEffect(() => {
        console.info(`%cuse${error?.message}`, 'color: #eed202');
    }, []);

    return (
        <>
            {!dismiss && (
                <StyledContent>
                    <Notification
                        onDismiss={() => setDismiss(true)}
                        text={description || translate('message.contentUnavailable')}
                        duration={0}
                    />
                </StyledContent>
            )}
        </>
    );
};
