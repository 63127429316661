import React, { memo, useEffect } from 'react';
import { useCookieConsent } from '~/components/features/Cookiebot/hooks/useCookieConsent';
import { useCookieBot } from '~/components/features/Cookiebot/hooks/useCookieConsent/useCookieBot';
import Script from 'next/script';
export type ConsentType = 'necessary' | 'statistics' | 'marketing' | 'preferences';

/**
 * Loads cookie consent, and reloads in the correct language if culture changes
 */
export const CookieConsent = memo(() => {
    const { setConsent } = useCookieConsent();
    const { getConsentFor } = useCookieBot();

    useEffect(() => {
        const handleConsentUpdate = () => {
            setConsent({
                marketing: getConsentFor('marketing'),
                necessary: getConsentFor('necessary'),
                statistics: getConsentFor('statistics'),
                preferences: getConsentFor('preferences'),
            });
        };

        window.addEventListener('CookiebotOnConsentReady', handleConsentUpdate);

        return () => {
            window.removeEventListener('CookiebotOnConsentReady', handleConsentUpdate);
        };
    }, [setConsent, getConsentFor]);

    return (
        <Script
            id="Cookiebot"
            src="https://consent.cookiebot.com/uc.js"
            data-cbid="f3a16c17-67da-431c-aa63-9208574b65d4"
            type="text/javascript"
            async
        ></Script>
    );
});
