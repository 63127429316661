import React, { FC } from 'react';
import { useBasket } from '$features/Basket';
import { Button } from 'ui';
import { useFrame, useTranslations } from '~/services/cms';
import clsx from 'clsx';
import styles from './BasketButton.module.css';
import { useAuthentication } from '~/shared/hooks';
import { isJPG } from 'utils';

export const BasketButton: FC = () => {
    const { isAuthenticated } = useAuthentication();
    const { data: basket } = useBasket();
    const translate = useTranslations()
    const { data: frameData } = useFrame();
    const staticLinks = frameData?.staticLinks;
    const { lines = [] } = basket || {};
    const basketLineLength = basket?.lines?.length || 0;

    if (isJPG() && !isAuthenticated) return null

    return (
        <Button
            className={clsx(styles.buttonPadding)}
            variant='link'
            href={staticLinks?.basketPage?.url || ''}
            iconName='shopping-cart'
            aria-label={translate('navigation.mainMenu.basket')}
        >
            {lines?.length ? (
                <span className={clsx(styles.iconBadge)} children={basketLineLength} />
            ) : null}
        </Button>
    );
};
