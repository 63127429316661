import React from 'react';
import { CmsModel } from 'api-types';
import { LinkDeprecated, Image } from 'ui';
import { StyledImageContainer } from '../styled';

export const SocialIcons = ({ icons }: { icons: CmsModel.SocialIcon[] }) => {
    return (
        <StyledImageContainer>
            {icons.map(({ image, link }, index) => {
                if (!image) return null;
                return (
                    <LinkDeprecated
                        key={`${image?.src}-${index}`}
                        href={link?.url}
                        title={link?.title}
                        target={link?.target || '_self'}
                    >
                        <Image
                            src={`${image?.src}`}
                            title={link?.title}
                            loading="lazy"
                            width={35}
                            height={20}
                            layout="intrinsic"
                            sizes="45px"
                        />
                    </LinkDeprecated>
                );
            })}
        </StyledImageContainer>
    );
};
