import styled from '@emotion/styled';
import { switchProp } from 'styled-tools';

export type LinkVariant = 'header' | 'subHeader' | 'link';

export const StyledNavigationLink = styled.a<{ variant?: LinkVariant }>(
    ({ theme: { colors } }) => ({
        display: 'inline-block',
        color: colors.grey90,
        textDecoration: 'none',
        '&:hover': {
            color: colors.black,
            textDecoration: 'underline',
        },
    }),
    switchProp('variant', {
        header: ({ theme }) => ({
            fontSize: `${theme.fontSizes.xl}`,
            fontWeight: theme.fontWeights.bold,
            marginBottom: theme.spaces[6],
        }),
        subHeader: ({ theme }) => ({
            fontSize: `${theme.fontSizes.sm}`,
            fontWeight: theme.fontWeights.bold,
            marginBottom: theme.spaces[4],
        }),
        link: ({ theme }) => ({
            fontSize: `${theme.fontSizes['2xs']}`,
            marginBottom: theme.spaces[2],
        }),
    })
);
