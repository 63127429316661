import { CmsModel } from 'api-types';

export function lookupNodeById(
    nodeId: string | undefined,
    haystack: CmsModel.NavigationNode[] = []
): CmsModel.NavigationNode | undefined {
    if (!nodeId) return undefined;

    for (let i = 0; i < haystack.length; i++) {
        const node = haystack[i];
        const { children = [] } = node;

        // Check if current node is parent
        if (node.link.id === nodeId) {
            return node;
        } else if (children?.length) {
            // Check if parent in children
            const node = lookupNodeById(nodeId, children);

            if (node) {
                return node;
            }
        }
    }
}
