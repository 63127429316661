import { VisuallyHidden, Image, Text, LinkDeprecated } from 'ui';
import NextLink from 'next/link';
import React, { memo } from 'react';
import { CmsModel } from 'api-types';

import clsx from 'clsx';
import styles from './PromotedChildren.module.css';

/**
 * A list of link images, with optional texts
 */
export const PromotedChildren = memo(({ nodes }: { nodes?: CmsModel.PromotedNavigationNode[] }) => (
    <div className={clsx(styles.promotedContent)}>
        {nodes?.map(({ image, link }, i) => (
            <div className={clsx(styles.promotedItem)} key={i}>
                {image?.src && (
                    <Image {...image} width={384} height={251} sizes="300px" objectFit="cover" />
                )}
                <div className={clsx(styles.itemOverlay)}>
                    {link.title && <Text className={clsx(styles.info)} variant="body" children={link.title} />}
                    {link?.url && (
                        <NextLink href={link.url} passHref prefetch={false}>
                            <LinkDeprecated className={styles.coverLink} href={link.url} {...link}>
                                <VisuallyHidden>{link.title}</VisuallyHidden>
                            </LinkDeprecated>
                        </NextLink>
                    )}
                </div>
            </div>
        ))}
    </div>
));
