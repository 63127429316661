import NextLink from 'next/link';
import React, { memo } from 'react';
import { CmsModel } from 'api-types';
import { LinkVariant, StyledNavigationLink } from './styled';

type Props = {
    link?: CmsModel.Link;
    /**
     * - 'header'       Top level link
     * - 'subHeader'    Sub level link for column header links
     * - 'link'         Sub level children link
     */
    variant?: LinkVariant;
};

/**
 * Navigation link with varieties.
 *
 */
export const NavigationLink = memo(({ link, variant = 'link' }: Props) => {
    if (!link?.url) {
        return <></>;
    }

    return (
        <NextLink href={link.url} passHref prefetch={false}>
            <StyledNavigationLink
                target={link.target}
                title={link.title}
                variant={variant}
                children={link.title}
            />
        </NextLink>
    );
});
