import React, { memo } from 'react';
import { NavigationLink } from '../NavigationLink';

import { CmsModel } from 'api-types';
import { weakKey } from '~/shared/utils/jsx';

import clsx from 'clsx';
import styles from './Menu.module.css';

export const Menu = memo(({ nodes }: { nodes: CmsModel.NavigationNode[]; }) => (
    <div className={clsx(styles.menuWrapper)}>
        <div className={clsx(styles.menu)}>
            {nodes?.map((subNode) => (
                <ul key={weakKey(subNode)}>
                    <NavigationLink link={subNode.link} variant="subHeader" />
                    {subNode.children?.map(({ link }, index) => (
                        <li key={index}>
                            <NavigationLink link={link} />
                        </li>
                    ))}
                </ul>
            ))}
        </div>
    </div>
));
